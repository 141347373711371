import React, { useState, useEffect } from 'react';
import config from './config';

// import { FontAwesomeIcon } from 'react-icons/fa'; // Import the filled icon

// import icon1 from './assest/icon/icon1.png';
// import icon2 from './assest/icon/icon2.png';
// import icon3 from './assest/icon/icon3.png';
import certificate from '../assets/certificate-svgrepo-com.png';
import SmallPettyFoodBusinessOperators from './business types/SmallPettyFoodBusinessOperators';
import DairyUnits from './business types/DairyUnits';
import VegetableOilProcessingUnits from './business types/VegetableOilProcessingUnits';
import SlaughteringHouse from './business types/SlaughteringHouse';
import FishPoultrySeller from './business types/FishPoultrySeller';
import MeatProcessing from './business types/MeatProcessing';
import FoodManufacturer from './business types/FoodManufacturer';
import ProprietaryFood from './business types/ProprietaryFood';
import FoodSupplements from './business types/FoodSupplements';
import RadiationProcessing from './business types/RadiationProcessing';
import SubstancesAddedToFood from './business types/SubstancesAddedToFood';
import ColdStorage from './business types/ColdStorage';
import AtmosphericControlledStorage from './business types/AtmosphericControlledStorage';
import StorageWithoutAtmosphericControlled from './business types/StorageWithoutAtmosphericControlled';
import Wholesaler from './business types/Wholesaler';
import TurnoverDisplay from './business types/Transporter';
import Transporter from './business types/Transporter';
import Distributor from './business types/Distributor';
import Retailer from './business types/Retailer';
import Hotel from './business types/Hotel';
import Restaurants from './business types/Restaurants';
import Club from './business types/Club';
import FoodVendingAgencies from './business types/FoodVendingAgencies';
import Dhaba from './business types/Dhaba';
import Importers from './business types/Importers';
import ECommerce from './business types/ECommerce';
import Relabellers from './business types/Relabellers';
import Other from './business types/Other';
import Caterer from './business types/Caterar';
import ManufacturerExporter from './business types/ManufacturerExporter';
import HeadOffice from './business types/HeadOffice';
import { statesAndDistricts } from './StateData';

const backendServerUrl = config.backendServerUrl;
function FoodRegistration() {
    const [formData, setFormData] = useState({
        name_of_applicant: '',
        name_of_company: '',
        email_id: '',
        mobile_no: '',
        food_category: '',
        type_of_business: '',
        house_no: '',
        area: '',
        city: '',
        district: '',
        state: '',
        pin: '',
        selection: '',
        years: 1,
        price: 0
    });
    const [errors, setErrors] = useState({});

    const [selectedSelection, setSelection] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedPrice, setSelectedPrice] = useState(0);
    const [foodCategoryValue, setFoodCategoryValue] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');


    const handleSelectionChange = (selection) => {
        setSelection(selection);
        setFormData(formData => ({
            ...formData,
            selection: selection
        }));
    };

    const handleYearChange = (years) => {
        setSelectedYear(years);
        setFormData(formData => ({
            ...formData,
            years: years
        }));
    };

    const handlePriceChange = (price) => {
        setSelectedPrice(price);
        setFormData(formData => ({
            ...formData,
            price: price
        }));
    };

    const changeFoodCategory = (event) => {
        const newValue = event.target.value;
        setFoodCategoryValue(newValue);
    };


    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedDistrict(''); // Reset district when state changes
        setFormData(formData => ({
            ...formData,
            state: event.target.value,
            district: ''
        }));
    };

    const handleDistrictChange = (event) => {
        setSelectedDistrict(event.target.value);
        setFormData(formData => ({
            ...formData,
            state: selectedState,
            district: event.target.value
        }));
    };



    useEffect(() => {
        // console.log(JSON.stringify(formData, null, 2));
    });

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();


        // const formData = new FormData();
        // formData.append('file', file);
        if (validateForm()) {
            try {
                // return ;
                const response = await fetch(`${backendServerUrl}/api/food_license`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
                //console.log('response');
                //console.log(response);


                // Handle response data if needed
                const data = await response.json();
                if (data.success) {
                    // alert("Registration successful!!")
                    //console.log('Success:', data);
                    // window.location.href = data.paymentURL
                    localStorage.setItem('paymentURL', data.paymentURL);
                    window.location.href = '/thank-you'

                }


            } catch (error) {
                alert("Registration Failed !!")
                console.error('Error:', error);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: files ? files[0] : value
        }));

        // Clear errors when user starts typing again
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));

    };

    const validateForm = () => {
        const errors = {};

        // Validate each field

        if (!formData.name_of_applicant) {
            errors.name_of_applicant = 'Name of applicant is required';
        }

        if (!formData.name_of_company) {
            errors.name_of_company = 'Name of company is required';
        }

        // Validation rules for email_id
        if (!formData.email_id) {
            errors.email_id = 'Email ID is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email_id)) {
            errors.email_id = 'Invalid email format';
        }

        // Validation rules for mobile_no
        if (!formData.mobile_no) {
            errors.mobile_no = 'Mobile number is required';
        } else if (!/^\d{10}$/.test(formData.mobile_no)) {
            errors.mobile_no = 'Mobile number must be 10 digits';
        }

        if (!formData.food_category) {
            errors.food_category = 'Food Category is required';
        }
        if (!formData.type_of_business) {
            errors.type_of_business = 'Kind Of Business is required';
        }
        // if (!formData.designation) {
        //     errors.designation = 'Designation is required';
        // }
        if (!formData.house_no) {
            errors.house_no = 'House number is required';
        }
        if (!formData.area) {
            errors.area = 'Area is required';
        }
        if (!formData.city) {
            errors.city = 'City is required';
        }
        if (!formData.district) {
            errors.district = 'District is required';
        }
        if (!formData.state) {
            errors.state = 'State is required';
        }
        if (!formData.pin) {
            errors.pin = 'PIN code is required';
        } else if (!/^\d{6}$/.test(formData.pin)) {
            errors.pin = 'PIN code must be a 6-digit number';
        }
        // if (!formData.no_of_years) {
        //     errors.no_of_years = 'Number of years is required';
        // } else if (formData.no_of_years <= 0) {
        //     errors.no_of_years = 'Number of years must be greater than 0';
        // }

        setErrors(errors);
        return Object.keys(errors).length === 0; // Return true if there are no errors
    };

    return (
        <div>
            <section className="relative " id="unique-section-id">
                <div className="ml-2 mr-2">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-1/2 top-15 pt-2 ">
                            <div className="text-center h-8 flex items-center justify-center lg:mr-8" style={{ backgroundColor: "rgb(0, 36, 107)" }}>
                                <h3 className="text-1xl font-bold mb-0" style={{ color: "white" }}>FOOD LICENCE REGISTRATION FORM</h3>
                            </div>

                            <div className="col-md-12 form-bg2 div-shadow-box p-4 bg-gray-100 lg:mr-8">
                                <div className="">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row flex flex-col space-y-4">
                                            <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="font-semibold mb-1">
                                                            Name of Applicant / आवेदक का नाम{" "}
                                                            <span className="text-red-900 text-lg font-bold">*</span>
                                                        </label>
                                                        <input
                                                            name="name_of_applicant"
                                                            value={formData.name_of_applicant}
                                                            onChange={handleChange}
                                                            type="text"
                                                            id="txtName"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Name of Applicant / Company"
                                                            required
                                                        />
                                                        {errors.name_of_applicant && (
                                                            <span className="text-red-500">
                                                                {errors.name_of_applicant}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="font-semibold mb-1">
                                                            Email Id /ईमेल आईडी{" "}
                                                            <span className="text-red-900 text-lg font-bold">*</span>
                                                        </label>
                                                        <input
                                                            value={formData.email_id}
                                                            onChange={handleChange}
                                                            name="email_id"
                                                            type="text"
                                                            maxLength="100"
                                                            id="txtEmail"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Email Id"
                                                            required
                                                        />
                                                        {errors.email_id && (
                                                            <span className="text-red-500">{errors.email_id}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="font-semibold mb-1">
                                                            Mobile No/मोबाइल नंबर{" "}
                                                            <span className="text-red-900 text-lg font-bold">*</span>
                                                        </label>
                                                        <input
                                                            value={formData.mobile_no}
                                                            onChange={handleChange}
                                                            name="mobile_no"
                                                            type="text"
                                                            id="txtPhone1"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="98XXXXXXXX (Without 0 or +91)"
                                                            title="Enter valid 10 digits mobile number"
                                                            required
                                                        />
                                                        {errors.mobile_no && (
                                                            <span className="text-red-500">{errors.mobile_no}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="name_of_company" className="font-semibold mb-1">
                                                            Name Of Business/Firm/Company /व्यवसाय/फर्म/कंपनी का नाम
                                                            <span className="text-red-900 text-lg font-bold">*</span>
                                                        </label>
                                                        <input
                                                            onChange={handleChange}
                                                            name="name_of_company"
                                                            type="text"
                                                            value={formData.name_of_company}
                                                            id="name_of_company"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            required
                                                        />

                                                        {errors.name_of_company && (
                                                            <span className="text-red-500">
                                                                {errors.name_of_company}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="ddlNatureBusiness" className="font-semibold mb-1">
                                                            Food Category / खाद्य श्रेणी का नाम{" "}
                                                            <span className="text-red-900 text-lg font-bold">*</span>
                                                        </label>
                                                        <select
                                                            value={formData.food_category}
                                                            onChange={handleChange}
                                                            name="food_category"
                                                            id="ddlNatureBusiness"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            required
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Dairy products and analogues, excluding products of food category 2.0">
                                                                Dairy products and analogues, excluding products of food category 2.0
                                                            </option>
                                                            <option value="Fats and oils, and fat emulsions">Fats and oils, and fat emulsions</option>
                                                            <option value="Edible ices, including sherbet and sorbet">Edible ices, including sherbet and sorbet</option>
                                                            <option value="Fruits and vegetables (including mushrooms and fungi, roots and tubers, pulses and legumes, and aloe vera)">
                                                                Fruits and vegetables (including mushrooms and fungi, roots and tubers, pulses and legumes, and aloe vera)
                                                            </option>
                                                            <option value="Confectionery">Confectionery</option>
                                                            <option value="Cereals and cereal products, derived from cereal grains, from roots and tubers, pulses, legumes">
                                                                Cereals and cereal products, derived from cereal grains, from roots and tubers, pulses, legumes
                                                            </option>
                                                            <option value="Bakery products">Bakery products</option>
                                                            <option value="Fish and fish products, including molluscs, crustaceans, and echinoderms">
                                                                Fish and fish products, including molluscs, crustaceans, and echinoderms
                                                            </option>
                                                            <option value="Eggs and egg products">Eggs and egg products</option>
                                                            <option value="Sweeteners, including honey">Sweeteners, including honey</option>
                                                            <option value="Salts, spices, soups, sauces, salads and protein products">
                                                                Salts, spices, soups, sauces, salads and protein products
                                                            </option>
                                                            <option value="Foodstuffs intended for particular nutritional uses">
                                                                Foodstuffs intended for particular nutritional uses
                                                            </option>
                                                            <option value="Beverages, excluding dairy products">Beverages, excluding dairy products</option>
                                                            <option value="Ready-to-eat savouries">Ready-to-eat savouries</option>
                                                            <option value="Prepared Foods">Prepared Foods</option>
                                                            <option value="Substances added to food">Substances added to food</option>
                                                            <option value="others">Others</option>
                                                        </select>
                                                        {errors.food_category && (
                                                            <span className="text-red-500">
                                                                {errors.food_category}
                                                            </span>
                                                        )}
                                                    </div>

                                                </div>


                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="ddlFoodCategory" className="font-semibold mb-1">
                                                        Kind Of Business / व्यवसाय का प्रकार{" "}
                                                        <span className="text-red-900 text-lg font-bold">*</span>
                                                    </label>
                                                    <select
                                                        value={formData.type_of_business}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            changeFoodCategory(event);
                                                        }}
                                                        name="type_of_business"
                                                        id="ddlFoodCategory"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        required
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="SmallPettyFoodBusinessOperators">1. Small / Petty Food Business Operators</option>
                                                        <option value="DairyUnits">2. Dairy Units including Milk Chilling Units</option>
                                                        <option value="VegetableOilProcessingUnits">3. Vegetable Oil Processing Units</option>
                                                        <option value="SlaughteringHouse">4. Slaughtering House</option>
                                                        <option value="FishPoultrySeller">5. Fish Seller / Poultry Products Seller</option>
                                                        <option value="MeatProcessing">6. Meat Processing</option>
                                                        <option value="FoodManufacturer">7. Manufacturer / Processor of any food other than Dairy Units, Vegetable Oil....</option>
                                                        <option value="ProprietaryFood">8. Proprietary Food</option>
                                                        <option value="FoodSupplements">9. Food or Health Supplements and Nutraceuticals etc.</option>
                                                        <option value="RadiationProcessing">10. Radiation processing of food</option>
                                                        <option value="SubstancesAddedToFood">11. Substances added to Food</option>
                                                        <option value="ColdStorage">12. Warehouse - Cold/Refrigerated</option>
                                                        <option value="AtmosphericControlledStorage">13. Warehouse – Atmospheric Controlled + Cold</option>
                                                        <option value="StorageWithoutAtmosphericControlled">14. Warehouse Without Atmospheric Controlled + Cold</option>
                                                        <option value="Transporter">15. Transporter</option>
                                                        <option value="Wholesaler">16. Wholesaler</option>
                                                        <option value="Distributor">17. Distributor</option>
                                                        <option value="Retailer">18. Retailer</option>
                                                        <option value="Hotel">19. Hotel</option>
                                                        <option value="Restaurants">20. Restaurants</option>
                                                        <option value="Club">21. Club/Canteen</option>
                                                        <option value="Caterer">22. Caterer</option>
                                                        <option value="FoodVendingAgencies">23. Food Vending Agencies</option>
                                                        <option value="Dhaba">24. Dhaba</option>
                                                        <option value="Importers">25. Importers</option>
                                                        <option value="Exporter">26. Exporters</option>
                                                        <option value="ECommerce">27. E-commerce</option>
                                                        <option value="HeadOffice">28. Head Office/Registered Office</option>
                                                        <option value="Relabellers">29. Relabellers And Repackers</option>
                                                        <option value="Other">30. Any Other Food Business not mentioned above</option>
                                                    </select>
                                                    {errors.type_of_business && (
                                                        <span className="text-red-500">
                                                            {errors.type_of_business}
                                                        </span>
                                                    )}
                                                </div>

                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    {foodCategoryValue ===
                                                        "SmallPettyFoodBusinessOperators" && (
                                                            <SmallPettyFoodBusinessOperators
                                                                setSelection={handleSelectionChange}
                                                                setSelectedYears={handleYearChange}
                                                                setSelectedPrice={handlePriceChange}
                                                            />
                                                        )}
                                                    {foodCategoryValue === "DairyUnits" && (
                                                        <DairyUnits
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "VegetableOilProcessingUnits" && (
                                                        <VegetableOilProcessingUnits
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "SlaughteringHouse" && (
                                                        <SlaughteringHouse
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "FishPoultrySeller" && (
                                                        <FishPoultrySeller
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "MeatProcessing" && (
                                                        <MeatProcessing
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "FoodManufacturer" && (
                                                        <FoodManufacturer
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "ProprietaryFood" && (
                                                        <ProprietaryFood
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "FoodSupplements" && (
                                                        <FoodSupplements
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "RadiationProcessing" && (
                                                        <RadiationProcessing
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "SubstancesAddedToFood" && (
                                                        <SubstancesAddedToFood
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "ColdStorage" && (
                                                        <ColdStorage
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "AtmosphericControlledStorage" && (
                                                        <AtmosphericControlledStorage
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue ===
                                                        "StorageWithoutAtmosphericControlled" && (
                                                            <StorageWithoutAtmosphericControlled
                                                                setSelection={handleSelectionChange}
                                                                setSelectedYears={handleYearChange}
                                                                setSelectedPrice={handlePriceChange}
                                                            />
                                                        )}
                                                    {foodCategoryValue === "Transporter" && (
                                                        <Transporter
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Wholesaler" && (
                                                        <Wholesaler
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Distributor" && (
                                                        <Distributor
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Retailer" && (
                                                        <Retailer
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Hotel" && (
                                                        <Hotel
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Restaurants" && (
                                                        <Restaurants
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Club" && (
                                                        <Club
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Caterer" && (
                                                        <Caterer
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "FoodVendingAgencies" && (
                                                        <FoodVendingAgencies
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Dhaba" && (
                                                        <Dhaba
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Importers" && (
                                                        <Importers
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Exporter" && (
                                                        <ManufacturerExporter
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "ECommerce" && (
                                                        <ECommerce
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "HeadOffice" && (
                                                        <HeadOffice
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Relabellers" && (
                                                        <Relabellers
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                    {foodCategoryValue === "Other" && (
                                                        <Other
                                                            setSelection={handleSelectionChange}
                                                            setSelectedYears={handleYearChange}
                                                            setSelectedPrice={handlePriceChange}
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Address</label>
                                                </div>
                                            </div>

                                            <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            value={formData.house_no}
                                                            onChange={handleChange}
                                                            name="house_no"
                                                            type="text"
                                                            maxLength="200"
                                                            id="txtHouse"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="HOUSE NO/SHOP NO/PLOT NO"
                                                        />
                                                        {errors.house_no && (
                                                            <span className="text-red-500">{errors.house_no}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            value={formData.area}
                                                            onChange={handleChange}
                                                            name="area"
                                                            type="text"
                                                            maxLength="200"
                                                            id="txtAreaLocality"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Area / Locality"
                                                        />
                                                        {errors.area && (
                                                            <span className="text-red-500">{errors.area}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            value={formData.city}
                                                            onChange={handleChange}
                                                            name="city"
                                                            type="text"
                                                            maxLength="200"
                                                            id="txtCity"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="City"
                                                        />
                                                        {errors.city && (
                                                            <span className="text-red-500">{errors.city}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            value={formData.pin}
                                                            onChange={handleChange}
                                                            name="pin"
                                                            type="text"
                                                            maxLength="6"
                                                            id="txtPin"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="PIN"
                                                        />
                                                        {errors.pin && (
                                                            <span className="text-red-500">{errors.pin}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label
                                                            htmlFor="state"
                                                            className="block text-gray-700 font-bold mb-2"
                                                        >
                                                            State:
                                                        </label>
                                                        <select
                                                            id="state"
                                                            value={selectedState}
                                                            onChange={handleStateChange}
                                                            className="block w-full p-2 border border-gray-300 rounded"
                                                        >
                                                            <option value="">Select a state</option>
                                                            {Object.keys(statesAndDistricts).map((state) => (
                                                                <option key={state} value={state}>
                                                                    {state}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label
                                                            htmlFor="district"
                                                            className="block text-gray-700 font-bold mb-2"
                                                        >
                                                            District:
                                                        </label>
                                                        <select
                                                            id="district"
                                                            value={selectedDistrict}
                                                            onChange={handleDistrictChange}
                                                            className="block w-full p-2 border border-gray-300 rounded"
                                                            disabled={!selectedState}
                                                        >
                                                            <option value="">Select a district</option>
                                                            {selectedState &&
                                                                statesAndDistricts[selectedState].map((district) => (
                                                                    <option key={district} value={district}>
                                                                        {district}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 top-15">
                                                <p>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="accept"
                                                            required
                                                            value="1"
                                                            className="mr-2"
                                                        />
                                                        I affirm my agreement to the Terms of Service by clicking the 'Submit Application' button. It is crucial to emphasize that the primary purpose of this form is to intricately detail my requisition to this private firm for the acquisition of a specialist for an FSSAI Registration License and this portal maintains a dedicated position as a private entity, separate from any government official or department, including the FSSAI Department.
                                                        .
                                                    </label>
                                                </p>

                                                <div className="flex justify-center mt-2">
                                                    <button
                                                        type="submit"
                                                        className="btn bg-green-500 text-black px-4 py-2 rounded-none"
                                                    >
                                                        Submit Application
                                                    </button>
                                                </div>
                                                <br></br>
                                                <br></br>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-1/2 top-15 pt-2">


                            <div className="text-center h-10 flex items-center justify-center lg:ml-8" style={{ backgroundColor: "rgb(0, 36, 107)" }}>
                                <h3 className="text-1xl font-bold mb-0" style={{ color: "white" }}>INSTRUCTIONS TO FILL FSSAI REGISTRATION ONLINE APPLY FORM</h3>
                            </div>


                            <div className="col-md-12 form-bg2 div-shadow-box p-4 bg-gray-100 lg:ml-8">
                                <div className="form-group" style={{ marginTop: "15px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>NAME OF APPLICANT / आवेदक का नाम :</strong> Applicant should enter their full name as per their PAN Card. आवेदक को अपना पूरा नाम आपके पैन कार्ड के अनुसार दर्ज करना चाहिए।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "35px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>EMAIL ID / ईमेल आईडी :</strong> Applicant should provide a valid and active email address. आवेदक को एक वैध और सक्रिय ईमेल पता प्रदान करना चाहिए।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "30px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>MOBILE NUMBER / मोबाइल नंबर :</strong> Applicant should enter their 10-digit mobile number. Ensure that the mobile number is active as it will be used for OTP verification. आवेदक को अपना 10 अंकों का मोबाइल नंबर दर्ज करना होगा। सुनिश्चित करें कि मोबाइल नंबर सक्रिय है क्योंकि इसका उपयोग ओटीपी सत्यापन के लिए किया जाएगा।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "50px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>NAME OF BUSINESS / FIRM / COMPANY / व्यवसाय / फर्म / कंपनी का नाम :</strong> Applicant should enter the registered name of their business, firm, or company. आवेदक को अपने व्यवसाय, फर्म या कंपनी का पंजीकृत नाम दर्ज करना चाहिए।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "45px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>NAME OF THE FOOD CATEGORY / खाद्य श्रेणी का नाम :</strong> Applicant should select the appropriate food category their business falls under from the given options. आवेदक को दिए गए विकल्पों में से उस उपयुक्त खाद्य श्रेणी का चयन करना चाहिए जिसके अंतर्गत उनका व्यवसाय आता है।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "45px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>KIND OF BUSINESS / व्यवसाय का प्रकार :</strong> Applicants should select the type of business they are operating from the provided list. आवेदकों को प्रदान की गई सूची से उस व्यवसाय के प्रकार का चयन करना चाहिए जो वे संचालित कर रहे हैं।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "30px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>COMPLETE BUSINESS ADDRESS / पता :</strong> Applicant should provide the full address of their business location. आवेदक को अपने व्यावसायिक स्थान का पूरा पता देना चाहिए।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "45px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>PINCODE / पिन कोड :</strong> Applicant should enter the pincode for their business address. आवेदक को अपने व्यावसायिक पते का पिनकोड दर्ज करना होगा।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "25px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>STATE / राज्य :</strong> Applicant should select the state where the business is located from the dropdown menu. आवेदक को ड्रॉपडाउन मेनू से उस राज्य का चयन करना चाहिए जहां व्यवसाय स्थित है।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "15px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>DISTRICT / जिला :</strong> Applicant should select the district within the state where the business is located from the dropdown menu. आवेदक को ड्रॉपडाउन मेनू से राज्य के भीतर उस जिले का चयन करना चाहिए जहां व्यवसाय स्थित है।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "25px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>UPLOAD PAN CARD :</strong> Applicant should Click on the "Choose File" button to upload a scanned copy of their PAN card. अपने पैन कार्ड की स्कैन की हुई कॉपी अपलोड करने के लिए "फ़ाइल चुनें" बटन पर क्लिक करें।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "25px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>TERMS OF SERVICE :</strong> Read the terms of service carefully. Check the box to agree to the terms of service, indicating your consent to the regulations. सेवा की शर्तें ध्यानपूर्वक पढ़ें. सेवा की शर्तों से सहमत होने के लिए बॉक्स को चेक करें, जो नियमों के प्रति आपकी सहमति दर्शाता है।
                                    </label>
                                </div>

                                {/* <div className="form-group" style={{ marginTop: "30px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>OTP AGREEMENT :</strong> Check the box to agree to share the OTP sent to your mobile number for verification purposes. सत्यापन उद्देश्यों के लिए अपने मोबाइल नंबर पर भेजे गए ओटीपी को साझा करने के लिए सहमत होने के लिए बॉक्स को चेक करें।
                                    </label>
                                </div>

                                <div className="form-group" style={{ marginTop: "25px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>VERIFICATION CODE :</strong> Enter the below 5-digit verification code in the given field. दिए गए फ़ील्ड में नीचे दिया गया 5-अंकीय सत्यापन कोड दर्ज करें।
                                    </label>
                                </div> */}

                                <div className="form-group" style={{ marginTop: "30px" }}>
                                    <label className="fcs-text-dark">
                                        <strong>SUBMIT BUTTON :</strong> Once all the details are reviewed and the verification code is entered, click on the "Submit" button. एक बार सभी विवरणों की समीक्षा हो जाने और सत्यापन कोड दर्ज करने के बाद, "सबमिट" बटन पर क्लिक करें ।
                                    </label>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>



            </section>
            <div className="container mx-auto px-4 py-8 border-t-4 border-t-black-500">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold">Frequently Asked Questions</h2>
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 px-4">
                        <ul>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">What is the full form of FSSAI?</h3>
                                    <p>FSSAI stands for the Food Safety and Standards Authority of India.</p>
                                </div>
                            </li>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">What is the purpose of the Food license?</h3>
                                    <p>The FSSAI Registration license is issued to ensure that food businesses in India comply with the safety and quality standards set by the FSSAI.</p>
                                </div>
                            </li>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">Who needs to obtain an FSSAI license?</h3>
                                    <p>Any business dealing in the food industry needs a Food License.</p>
                                </div>
                            </li>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">Is it mandatory to renew the FSSAI Registration license?</h3>
                                    <p>Yes, the FSSAI Registration license must be renewed before its expiry date for continued operations.</p>
                                </div>
                            </li>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">Is there a penalty for operating without a Food license?</h3>
                                    <p>Yes, operating without a valid FSSAI license is a legal offense and can lead to penalties, fines, and even the closure of the business.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 px-4">
                        <ul>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">Can I apply for an FSSAI Registration license online myself?</h3>
                                    <p>Yes, the FSSAI license application can be submitted online through the Foscos portal.</p>
                                </div>
                            </li>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">Is FSSAI registration the same as the FSSAI license?</h3>
                                    <p>No, FSSAI registration is for small-scale businesses, while the FSSAI license is for medium and large-scale businesses. The registration has a more straightforward process and lower fees.</p>
                                </div>
                            </li>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">Can an FSSAI Registration license be suspended or canceled?</h3>
                                    <p>Yes, the Food license can be suspended or canceled if the food business fails to comply with the food safety and quality regulations.</p>
                                </div>
                            </li>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">Can an FSSAI Registration license be modified?</h3>
                                    <p>Yes, a Food license can be modified to accommodate changes in the business's scope, scale, or other relevant details.</p>
                                </div>
                            </li>
                            <li className="mb-6">
                                <div className="nobox">
                                    <h3 className="text-lg font-semibold mb-2">How does this website connect us with the right experts?</h3>
                                    <p>Our website will connect you with the right experts based on the Information Form you filled out.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default FoodRegistration;